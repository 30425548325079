const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT正在安全地保管您的个人信息。<br>本公司将使用cookie和其他追踪技术提高网页性能，并提供个人定制的广告，再将与您相关的信息以流量分析与访问者统计为目的保存，并达到可持续改善服务的最终目标。<br>若点击\"全部同意\"，则视为同意将cookie保存至您的设备，若点击\"全部拒绝\"，则不会保存除必要cookie外的任何信息。<br>无论何时您都可以通过<a href=\"#\">cookie政策</a>确认详细内容并更改或撤回同意事项。",
        "BtnApply": "全部同意",
        "BtnDeny": "全部拒绝",
        "BtnApplyOptional": "选择同意",
        "BtnSetting": "设置"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
